<template>
  <div class="topbar-item">
    <div
      class="
        btn btn-icon
        w-auto
        btn-clean
        d-flex
        align-items-center
        btn-lg
        px-2
      "
      id="kt_quick_user_toggle"
    >
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        Olá,
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ currentUser.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bold m-0">
          Perfil do Usuário
          <small class="text-muted font-size-sm ml-2">0 messages</small>
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              class="symbol-label"
              :src="currentUser.profile_photo_url"
              alt=""
            />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <router-link
             to="/dadosUsuario"
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
              "
            >
              {{ currentUser.name }}
            </router-link>
            <!--            <div class="text-muted mt-1">Application Developer</div>-->
            <div class="navi mt-2">
                <router-link
             to="/dadosUsuario" href="" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ currentUser.email }}
                  </span>
                </span>
                </router-link>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sair
            </button>
            <button
              class="btn btn-light-dark btn-bold mt-2"
              @click="showModalSenha()"
            >
              Trocar Senha
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/General/Notification2.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/dadosUsuario">
                  <div class="font-weight-bold">
                    Meus dados
                    <span
                      class="label label-light label-inline font-weight-bold"
                    >
                      em breve
                    </span>
                  </div>
                </router-link>
                <div class="text-muted">Account settings and more</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  Minhas Mensagens
                  <span class="label label-light label-inline font-weight-bold">
                    em breve
                  </span>
                </div>
                <div class="text-muted">Tarefas e mensagens</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/icons/Files/Selected-file.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  Registro de Atividades
                  <span class="label label-light label-inline font-weight-bold">
                    em breve
                  </span>
                </div>
                <div class="text-muted">Logs e notificações</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            to="/"
            @click.native="closeOffcanvas"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="media/svg/icons/Communication/Mail-opened.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">
                  Recados
                  <span class="label label-light label-inline font-weight-bold">
                    em breve
                  </span>
                </div>
                <div class="text-muted">Últimas tarefas</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <div class="separator separator-dashed my-7"></div>
        <!--begin::Notifications-->

        <!--end::Notifications-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>

    <v-dialog v-model="senha_modal" max-width="600">
      <template>
        <v-card elevation="9" shaped>
          <v-card-title>Troca Senha</v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <form class="container col-md-10">
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha
                  </label>
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    :style="
                      (form.password1.length > 4) &
                      (form.password1 == form.password2)
                        ? 'border-color:green;'
                        : 'border-color:red;'
                    "
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password1"
                    autocomplete="off"
                  />
                </div>
                <span v-if="form.password1.length <= 4"
                  >menimo de 5 digitos</span
                >
              </div>

              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    :style="
                      (form.password1.length > 4) &
                      (form.password1 == form.password2)
                        ? 'border-color:green;'
                        : 'border-color:red;'
                    "
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password2"
                    autocomplete="off"
                  />
                  <span
                    v-if="
                      (form.password1 != form.password2) &
                      (form.password2.length > 4)
                    "
                    >não esta igual</span
                  >
                </div>
              </div>

              <span v-if="!(form.password1 == form.password2)">
                O botão Salvar aparece depois que as senhas estejam iguais
              </span>
            </form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class=""
              color="red darken-1"
              text
              @click="modal_create_regra = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              v-if="
                (form.password1 == form.password2) & (form.password1.length > 4)
              "
              @click="submitSenha()"
              color="blue darken-1"
              text
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import UsuarioService from "@/core/services/usuario.service";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  name: "KTQuickUser",
  mixins: [fireAlert],
  data() {
    return {
      senha_modal: false,

      form: {
        password1: "10203040",
        password2: "",
        id: null,
      },
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    hideModalSenha() {
      this.senha_modal = false;
    },
    showModalSenha() {
      this.form.id = UsuarioService.getUsuario()["id"];
      this.senha_modal = true;
    },
    async submitSenha() {
      let a = {
        passaword: this.form.password1,
        id: this.form.id,
      };
      console.log(a);
  
      await this.$store
        .dispatch("configUsuarios/troca_senha", a)
        .catch(() => {})
        .finally(() => {
          this.hideModalSenha();

        });
                this.fireAlert(this.mensagem_alert);


    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    mensagem_alert() {
      return this.$store.state.configUsuarios.mensagem_alert;
    },
  },
};
</script>
