var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"kt_header",staticClass:"header",class:_vm.headerClasses,attrs:{"id":"kt_header"}},[_c('div',{staticClass:"container-fluid row align-items-center justify-content-between"},[_c('div',{ref:"kt_header_menu_wrapper",staticClass:"header-menu-wrapper header-menu-wrapper-left"},[(true)?_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile",class:_vm.headerMenuClasses,attrs:{"id":"kt_header_menu"}}):_vm._e()]),_c('div',{staticClass:"col-md-7 col-12 d-flex mt-1"},[_c('v-autocomplete',{ref:"autocomplete",attrs:{"items":_vm.items,"filter":_vm.customFilter,"loading":_vm.isLoading,"search-input":_vm.search2,"clearable":"","deletable-chips":"","item-text":"search","item-value":"id","label":_vm.pasta && _vm.pasta.id
            ? 'Pesquise na pasta ' + (_vm.pasta.text || _vm.pasta.nome)
            : 'Procure ...',"solo":"","append-outer-icon":"mdi-file-search"},on:{"update:searchInput":function($event){_vm.search2=$event},"update:search-input":function($event){_vm.search2=$event},"click":_vm.handleClickInside,"input":function($event){return _vm.goDoc()},"click:append-outer":function($event){return _vm.search()}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Procure seus "),_c('strong',[_vm._v("documentos")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
            var attr = ref.attr;
            var on = ref.on;
            var item = ref.item;
            var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"warning"}},'v-chip',attr,false),on),[_c('b-icon',{attrs:{"icon":"file-earmark","font-scale":"0.9","aria-hidden":"true"}}),_c('span',[_vm._v(" "+_vm._s(item.nome_original.length > 30 ? item.nome_original.substr(0, 40) + "... " : item.nome_original))])],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_c('div',[_c('b-icon',{attrs:{"icon":"file-earmark","font-scale":"0.9","aria-hidden":"true"}}),(item.nome_original)?_c('i',{staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(item.nome_original.charAt(0))+" ")]):_vm._e()],1)]),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{},[_vm._v(_vm._s(item.nome_original))])])],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('KTTopbar')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }