export default [

    {
        title: "Perfil",
        botoes: [
            {
                title: "Criar",
                route: "/createPerfil",
                permissao: "c_Perfil",
            },
            {
                title: "Listar",
                route: "/perfil",
                permissao: "ra_Perfil",
            },
        ],
    },

    {
        title: "Permissoes",
        botoes: [
            {
                title: "Criar",
                route: "/createRule",
                permissao: "c_Role",
            },
            {
                title: "Listar",
                route: "/permissoes",
                permissao: "ra_Role",
            },
        ]
    },
    {
        title: "Empresa",
        botoes: [
            {
                title: "Criar",
                route: "/createEmpresa",
                permissao: "ra_empresa",
            },
            {
                title: "Listar",
                route: "/empresa",
                permissao: "ra_empresa",
            },
        ]
    },
    {
        title: "Filial",
        botoes: [
            {
                title: "Criar",
                route: "/createFilial",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/filial",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Funcionarios",
        botoes: [
            {
                title: "Criar",
                route: "/createFuncionario",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/gerenFuncionarios",
                permissao: "ra_Usuario",
            },
        ],
    },
    {
        title: "Setor",
        botoes: [
            {
                title: "Criar",
                route: "/createSetor",
                permissao: "c_Setor",
            },
            {
                title: "Listar",
                route: "/setor",
                permissao: "ra_Setor",
            },
        ],
    },
    {
        title: "Grupo",
        botoes: [
            {
                title: "Criar",
                route: "/createGrupo",
                permissao: "c_Grupo",
            },
            {
                title: "Listar",
                route: "/grupo",
                permissao: "ra_Grupo",
            },
        ],
    },

    // {
    //     title: "Contratos",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createContrato",
    //             permissao: "c_Contrato",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/contrato",
    //             permissao: "ra_Contrato",
    //         },
    //     ],
    // },



    {
        title: "Produtos",
        botoes: [
            {
                title: "Criar",
                route: "/createProduto",
                permissao: "c_Produto",
            },
            {
                title: "Listar",
                route: "/produto",
                permissao: "ra_Produto",
            },
        ],
    },

    {
        title: "Tipos de Produtos",
        botoes: [
            {
                title: "Criar",
                route: "/createTipoProduto",
                permissao: "c_TipoProduto",
            },
            {
                title: "Listar",
                route: "/tipoProduto",
                permissao: "ra_TipoProduto",
            },
        ],
    },

    {
        title: "Modos de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createModoPagamento",
                permissao: "c_FinanModoPagamento",
            },
            {
                title: "Listar",
                route: "/modoPagamento",
                permissao: "ra_FinanModoPagamento",
            },
        ],
    },

    {
        title: "Formas de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createFormaPagamento",
                permissao: "c_FinanFormaPagamento",
            },
            {
                title: "Listar",
                route: "/formaPagamento",
                permissao: "ra_FinanFormaPagamento",
            },
        ],
    },
    {
        title: "Criar",
        botoes: [
            {
                title: "Criar",
                route: "/createFinanPedidoRecebimento",
                permissao: "c_FinanFormaPagamento",
            },
            {
                title: "Listar",
                route: "/finanPedidoRecebimento",
                permissao: "ra_FinanFormaPagamento",
            },
        ],
    },

    {
        title: "Usuario Ponto",
        botoes: [
            {
                title: "Criar",
                route: "/createUsuarioPonto",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/UsuarioPonto",
                permissao: "ra_Usuario",
            },
        ],
    },
    {
        title: "Chamados",
        botoes: [
            {
                title: "Criar",
                route: "/createChamados",
                permissao: "c_Chamados",
            },
            {
                title: "Listar",
                route: "/chamados",
                permissao: "ra_Chamados",
            },
        ],
    },
    {
        title: "Caixa",
        botoes: [
            {
                title: "Criar",
                route: "/criar-caixa",
                permissao: "c_Caixa",
            },
            {
                title: "Listar",
                route: "/caixa",
                permissao: "ra_Caixa",
            },
        ],
    },
    {
        title: "Tipo Protocolo",
        botoes: [
            {
                title: "Criar",
                route: "/createTipoProtocolo",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "Listar",
                route: "/tipoProtocolo",
                permissao: "ra_TipoProtocolo",
            },
        ],
    },
    {
        title: "Protocolos",
        botoes: [
            {
                title: "Criar",
                route: "/createProtocolo",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "Listar",
                route: "/protocolos",
                permissao: "ra_Protocolo",
            },
        ],
    },
    {
        title: "Configuração Pasta Drive",
        botoes: [
            {
                title: "Criar",
                route: "/createConfigPastaDrive",
                permissao: "c_ConfigPastaDrive",
            },
            {
                title: "Listar",
                route: "/configPastaDrive",
                permissao: "ra_ConfigPastaDrive",
            },
        ],
    },

];
