export default [
  {
    title: "Página Inicial",
    route: "/dashboard",
    permissao: "paginainicial",
    iconClass: "fas fa-chalkboard-teacher",
  },
  {
    title: "Gerenciamento",
    route: null,
    permissao: "c_Usuario",
    iconClass: "fas fa-cogs",
    subItems: [
      {
        title: "Permissões",
        route: "/permissoes",
        permissao: "c_Role",
      },

      {
        title: "Empresa",
        route: "/empresa",
        permissao: "c_Empresa",
      },
      {
        title: "Filial",
        route: "/filial",
        permissao: "c_Filial",
      },

      {
        title: "Perfil",
        route: "/perfil",
        permissao: "c_Perfil",
      },
      {
        title: "Setor",
        route: "/setor",
        permissao: "c_Setor",
      },
      {
        title: "Grupo",
        route: "/grupo",
        permissao: "c_Grupo",
      },
    ],
  },

  {
    title: "RH",
    route: null,
    permissao: "c_Usuario",
    iconClass: "fas fa-user-cog",
    subItems: [
      {
        title: "Funcionários",
        route: "/gerenFuncionarios",
        permissao: "c_Usuario",
      },
    ],
  },
  {
    title: "Controle Arquivex",
    route: null,
    permissao: "ra_Pastas",
    iconClass: "fas fa-folder-open",
    subItems: [
      {
        title: "Gerenciamento de arquivos",
        route: "/arquivex",
        permissao: "ra_Documentos",
      },
      {
        title: "Certificado",
        route: "/createCertificado",
        permissao: "c_Usuario",
      },
      {
        title: "Documentos Eletronicos",
        route: "/documetosEletronicos",
        permissao: "c_Filial",
      },
    ],
  },

  {
    title: "Gerência de Protocos",
    route: null,
    permissao: "ra_TipoProtocolo",
    iconClass: "far fa-comment-dots",
    subItems: [
      {
        title: "Pasta do drive",
        route: "/configPastaDrive",
        permissao: "ra_ConfigPastaDrive",
      },
      {
        title: "Tipo Protocolo",
        route: "/tipoProtocolo",
        permissao: "ra_TipoProtocolo",
      },
      {
        title: "Protocolos",
        route: "/protocolos",
        permissao: "ra_TipoProtocolo",
      },
    ],
  },

  // {
  //   title: 'Financeiro',
  //   route: null,
  //   permissao: "ra_FinanContasReceber",
  //   iconClass: 'fas fa-dollar-sign',
  //   subItems: [


  //     {
  //       title: 'Produtos',
  //       route: '/produto',
  //       permissao: 'ra_Produto'
  //     },
  //     {
  //       title: 'Tipo Produto',
  //       route: '/tipoProduto',
  //       permissao: 'ra_TipoProduto' 
  //     },
  //     {
  //       title: 'Modos de pagamento',
  //       route: '/modoPagamento',
  //       permissao: 'ra_FinanModoPagamento'
  //     },




  //     {
  //       title: 'Formas de Pagamento',
  //       route: '/formaPagamento',
  //       permissao: 'ra_FinanFormaPagamento'

  //     },
  //     {
  //       title: 'Pedidos a receber',
  //     route: '/finanPedidoRecebimento',
  //     permissao:'ra_FinanContasReceber'
  // },


  //     // {
  //     //     title: 'Pedidos de Compra',
  //     //     route: '/pedidoCompra',
  //     //     permissao:'ra_FinanPedidoCompra'
  //     // },
  //     // {
  //     //     title: 'Pedidos de Recebimento',
  //     //     route: '/finanPedidoRecebimento',
  //     //     permissao:'ra_FinanPedidoRecebimento'
  //     // },
  //     // {
  //     //     title: 'Contas a Pagar',
  //     //     route: '/finanContasPagar',
  //     //     permissao:'ra_FinanContasPagar'
  //     // },
  //     // {
  //     //     title: 'Contas a Receber',
  //     //     route: '/finanContasReceber',
  //     //     permissao:'ra_FinanContasReceber'
  //     // },


  //   ]
  // },
  {
    title: 'Ponto',
    route: null,
    permissao: "c_Usuario",
    iconClass: 'fas fa-registered',
    subItems: [


      {
        title: 'Historico',
        route: '/historico',
        permissao: 'c_Usuario'
      },

      {
        title: 'Usuario Ponto',
        route: '/UsuarioPonto',
        permissao: 'c_Usuario'
      },
    ]
  },

  {
    title: "Gerenciamento Armazenamento",
    route: null,
    permissao: "ra_Filial",
    iconClass: "fas fa-home",
    subItems: [
      {
        title: "caixa",
        route: "/caixa",
        permissao: "ra_Caixa",
      },
    ],
  },

  {
    title: 'suporte',
    route: null,
    permissao: "ra_Chamados",
    iconClass: 'fas fa-comments',
    subItems: [


      {
        title: 'chamados',
        route: '/chamados',
        permissao: 'ra_Chamados'
      },


    ]
  },




  // {
  //   title: "Imoveis",
  //   route: null,
  //   permissao: "ra_Imovel",
  //   iconClass: "fas fa-home",
  //   subItems: [
  //     {
  //       title: "Imoveis",
  //       route: "/imovel",
  //       permissao: "ra_Imovel",
  //     },
  //   ],
  // },











];
