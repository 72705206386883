<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div class="container-fluid row align-items-center justify-content-between">
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left"
        ref="kt_header_menu_wrapper"
      >
        <div
          v-if="true"
          id="kt_header_menu"
          ref="kt_header_menu"
          class="header-menu header-menu-mobile"
          v-bind:class="headerMenuClasses"
        >
          <!-- <b-dropdown variant="warning" class="my-class" size="sm">
            <template v-slot:button-content>
              <span style="font-weight: 500">
                <i class="mr-2 fas fa-home"></i>{{ filialSelecionada }}
              </span>
            </template>

            <div
              v-for="(filial, index) in lista_filiais_autorizadas"
              :key="index"
              @click="updateFilial(filial.id)"
            >
              <b-dropdown-item> • {{ filial.filial }} </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
            </div>
          </b-dropdown> -->
        </div>
      </div>
      <div class="col-md-7 col-12 d-flex mt-1">
        <v-autocomplete
          ref="autocomplete"
          v-model="model"
          :items="items"
          :filter="customFilter"
          :loading="isLoading"
          :search-input.sync="search2"
          clearable
          deletable-chips
          item-text="search"
          item-value="id"
          :label="
            pasta && pasta.id
              ? 'Pesquise na pasta ' + (pasta.text || pasta.nome)
              : 'Procure ...'
          "
          solo
          @click="handleClickInside"
          @input="goDoc()"
          @click:append-outer="search()"
          append-outer-icon="mdi-file-search"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Procure seus
                <strong>documentos</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-chip
              v-bind="attr"
              :input-value="selected"
              color="warning"
              class="white--text"
              v-on="on"
            >
              <b-icon
                icon="file-earmark"
                font-scale="0.9"
                aria-hidden="true"
              ></b-icon>
              <span>
                {{
                  item.nome_original.length > 30
                    ? item.nome_original.substr(0, 40) + "... "
                    : item.nome_original
                }}</span
              >
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
              color="indigo"
              class="text-h5 font-weight-light white--text"
            >
              <div>
                <b-icon
                  icon="file-earmark"
                  font-scale="0.9"
                  aria-hidden="true"
                ></b-icon>
                <i v-if="item.nome_original" style="font-size: 20px">
                  {{ item.nome_original.charAt(0) }}
                </i>
              </div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span style="">{{ item.nome_original }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- <template v-slot:append-outer>
         
              <v-icon>mdi-file-search</v-icon>
          </template> -->
        </v-autocomplete>
        <!-- <v-btn
          class="ml-1 mt-2"
          @click.prevent="search()"
          color="primary"
          elevation="10"
          icon
        >
          <v-icon>mdi-file-search</v-icon></v-btn
        > -->
      </div>

      <!-- end:: Header Menu -->

      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import FilialService from "@/core/services/filial.service";

export default {
  name: "KTHeader",
  components: {
    KTTopbar,
  },
  data: () => ({
    isLoading: false,
    items: [],
    model: null,
    search2: null,
    menu_open: false,
    tab: null,
  }),
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    // lista_filiais_autorizadas() {
    //   return this.$store.state.sistema.lista_filiais_autorizadas;
    // },

    // filialSelecionada() {
    //   let filial_id = this.$store.state.sistema.id_filial_selecionada;
    //   if (!this.lista_filiais_autorizadas || !filial_id)
    //     return "Selecionar filial";
    //   return this.lista_filiais_autorizadas[filial_id].filial;
    // },

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    lista_documentos() {
      return this.$store.state.documentos.lista_documentos;
    },
    pasta() {
      return this.$store.state.pastas.pasta;
    },
  },

  watch: {
    model(val) {
      if (val != null) this.tab = 0;
      else this.tab = null;
    },
  },
  methods: {
    // async updateFilial(value) {
    //   await this.$store.dispatch("sistema/setFilial", value);
    //   this.$store.dispatch("sistema/listar_permissoes_sede", value);

    //   window.location.reload();
    // },
    goDoc() {
      this.handleClickInside();
      this.$router.push({
        name: "viewfile",
        params: { arquivo_id: this.model },
      });
    },

    async search() {
      // Items have already been loaded
      let a = this.search2;
      this.search2 = "";
      this.isLoading = true;
      this.items = [];
      let params = {};

      if (this.pasta && this.pasta.id) {
        params = { pasta_id: this.pasta.id, pesquisa: a };
      } else {
        params = { pesquisa: a };
      }
      await this.$store.dispatch("documentos/listar_documentos", params);
      this.items = this.lista_documentos;
      this.isLoading = false;
      this.search2 = a;
      this.handleClickInside();
    },
    handleClickInside() {
      this.$refs.autocomplete.$el.querySelector("input").click();
    },
    outside() {
      console.log("a");
      this.menu_open = false;
    },
    customFilter(item, queryText, itemText) {
      // Quebra o texto de pesquisa em palavras
      const palavras = queryText.toLowerCase().split(" ");
      const text = itemText.toLowerCase();

      // Verifica se todas as palavras de pesquisa estão no item
      return palavras.every((word) => text.includes(word));
    },
  },
};
</script>

<style>
.my-class .dropdown-menu {
  max-height: 600px;
  overflow-y: auto;
}
</style>

